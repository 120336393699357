import { render, staticRenderFns } from "./CommentFormModal.vue?vue&type=template&id=3085d394&scoped=true"
import script from "./CommentFormModal.vue?vue&type=script&lang=js"
export * from "./CommentFormModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3085d394",
  null
  
)

export default component.exports