<template>
  <b-modal
    id="modal-comment-form"
    title="Chỉnh sửa nhận xét"
    size="md"
    centered
    hide-footer
    @close="onCloseButton"
    @show="onShow"
    @hide="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <!-- Buổi coach -->
        <basic-select
          v-validate="{
            required: true,
          }"
          :value.sync="form.coachSection"
          :options="coachSectionList"
          :disabled="isEdit"
          :state="validateState('coachSection')"
          :invalidFeedback="errors.first('coachSection')"
          name="coachSection"
          required
          data-vv-as="Buổi coach"
          value-label="coachDate"
          track-by="id"
          label="Buổi coach"
          placeholder="---Chọn buổi coach 1 - n ---"
          changeValueByObject
        />

        <!-- Nhận xét -->
        <basic-text-editors
          label="Nhận xét"
          placeholder="Nhập nhận xét cho buổi coach"
          name="comment"
          data-vv-as="Nhận xét"
          v-validate="{
            required: true,
          }"
          required
          :state="validateState('comment')"
          :invalidFeedback="errors.first('comment')"
          :value.sync="form.comment"
        ></basic-text-editors>

        <!-- Actions -->
        <div class="d-flex justify-content-end align-items-center">
          <b-button class="btn mr-2" type="button" @click="onClickCancelButton">
            Hủy
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>

<script>
import { commentMixin } from '../mixins/CommentFormModal';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('calender');
import { MODAL_TYPE } from '@/core/plugins/constants.js';

export default {
  name: 'CommentFormModal',

  mixins: [commentMixin],

  props: {
    groupId: {
      type: String,
      default: null,
    },
    coachSelectionId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      form: {
        coachSection: '',
        comment: '',
      },

      loading: false,
      coachSectionList: [],
    };
  },

  computed: {
    ...mapState(['calendar']),

    isEdit() {
      return this.typeForm === MODAL_TYPE.EDIT;
    },
  },

  watch: {
    typeForm() {
      this.setDataUpdate();
    },

    optionTypes(val = []) {
      if (val.length && this.isEdit) {
        this.schedule.type = this.optionTypes.find(
          (item) => item.value === this.calendar.type.toString(),
        );
      }
    },
  },

  mounted() {
    this.setDataUpdate();
  },

  methods: {
    onShow() {
      this.getCoachSections();
    },

    async getCoachSections() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          '/CalendarTrainingComment/GetListCoach1n',
          {
            params: {
              trainingGroupId: this.groupId,
            },
          },
        );

        if (!meta.success)
          return this.showToastrMessage(error.message, 'error');

        this.coachSectionList = data.map((el) => ({
          ...el,
          coachDate: this.convertTimestampToDate(el.coachDate),
        }));
        this.form.coachSection = this.coachSectionList.find(
          (el) => el.id === this.coachSelectionId,
        );
        this.form.comment = this.form.coachSection.comment;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },

    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },

    resetModal() {
      this.form = {
        coachSection: '',
        comment: '',
      };

      this.loading = false;
      this.coachSectionList = [];
      this.$emit('reset');
    },

    setDataUpdate() {
      if (!this.isEdit) return;

      const data = { ...this.calendar };
      this.schedule.name = data.name;
      this.schedule.type = data.type;
    },

    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },

    async handleSubmit() {
      const params = {
        comment: this.form.comment,
        calendarTrainingId: this.form.coachSection.id,
      };

      try {
        this.loading = true;
        const { meta, error } = await this.$api.put(
          '/CalendarTrainingComment/UpdateCommentCoach1N',
          params,
        );

        if (!meta.success)
          return this.showToastrMessage(error.message, 'error');

        this.showToastrMessage('Chỉnh sửa nhận xét thành công', 'success');

        this.onClickCancelButton();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },

    onClickCancelButton() {
      this.$bvModal.hide('modal-comment-form');
      this.$bvModal.show('modal-comments-list');
    },

    onCloseButton() {
      this.$bvModal.show('modal-comments-list');
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
